export default {
	siteTitle: 'Máltækni - Stofnun Árna Magnússonar í íslenskum fræðum',
	pageSize: 50,

	//apiRoot: 'http://130.208.178.119:8000',
	apiRoot: '/django',
	//vefurRoot: 'http://blondal.arnastofnun.is/django/vefur',
	vefurRoot: '/django/vefur',
	//apiRoot: process.env.REACT_APP_API_ROOT,

	gaTrackingId: ''
}
